<template lang="pug">
Dialog.dialog-sangria(:modal="true" header="Autorizar estorno" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    form(header='Autorizar estorno')
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12
                label.form-label Tipo de Operação:
                SelectButton(v-model='$v.model.ie_tipo_operacao.$model' :options='options.tipoOperacao' optionValue='value' dataKey='value' optionLabel='label')
                .feedback--errors(v-if='submitted && $v.model.ie_tipo_operacao.$error')
                    .form-message--error(v-if="!$v.model.ie_tipo_operacao.required") <b>Tipo de operação</b> é obrigatório.
            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_tratativa.$error }")
                label.form-label Tratativa:
                Textarea(type='text' rows="3" v-model='$v.model.ds_tratativa.$model')
                .feedback--errors(v-if='submitted && $v.model.ds_tratativa.$error')
                    .form-message--error(v-if="!$v.model.ds_tratativa.required") <b>Tratativa</b> é obrigatório.
            .p-col-12.ta-right
                Button(label='Confirmar' type="button" @click='handleSubmit()')
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Caixas } from "@/middleware";
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import Textarea from 'primevue/textarea';

    import { required } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
            ConfirmDialog, Textarea,
        },
        props: [
            'display',
            'cd_estorno',
        ],
        computed: {
            show: {
                get() { 
                    if (!this.display) this.resetModel()
                    return this.display;
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        data () {
            return {
                model: {
                    ie_tipo_operacao: null,
                    ds_tratativa: '',
                },
                options: {
                    tipoOperacao: [
                        {
                            label: "Autorizar",
                            value: "ATR",
                        },
                        {
                            label: "Não autorizar",
                            value: "NAT",
                        },
                    ],
                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                submitted: false,
            }
        },
        validations () {
            let v = {
                model: {
                    ie_tipo_operacao: { required },
                    ds_tratativa: { required },
                }
            }
            return v
        },
        methods: {
            resetModel() {
                this.waiting = true
                this.waitingForm = false
                this.submitted = false
                this.model.ds_tratativa = ''
                this.model.ie_tipo_operacao = null
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                dataSend['id'] = this.cd_estorno

                Caixas.autorizacaoEstorno(dataSend).then(response => {
                    this.submitted = false;
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.success(
                            "Autorização feita com sucesso."
                        );
                        this.$emit('close')
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="scss">

.dialog-sangria {
    width: 600px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
