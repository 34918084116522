<template lang="pug">
    .main-wrapper.orcamentos-listar 
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Orçamentos</b>

        ListaCirurgias

        Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressaoGuias.visible")
            .ta-center.my-2
                div(v-if='dialogOpcoesImpressaoGuias.visible' v-show='false')
                    Imprimir(ref='boxImprimir' :id='dialogOpcoesImpressaoGuias.cd_transacao' :guias='guias' @setGuias='setGuias')
                ProgressSpinner.waitingImprimir(v-if='dialogOpcoesImpressaoGuias.waiting' strokeWidth="6")
                div(v-else)
                    Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirGuias(true)')
                    Button.p-button-success(label='Impressão A4' icon='jam jam-printer' @click='imprimirGuias()')

        Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressao.visible")
            .ta-center.my-2
                ProgressSpinner.waitingImprimir(v-if='dialogOpcoesImpressao.waiting' strokeWidth="6")
                div(v-else)
                    Button.p-button-success(
                        label='Para o Médico' icon='jam jam-medical'
                        @click='imprimirOrcamento(dialogOpcoesImpressao.id, true)')
                    Button.p-button-info.ml-2(
                        label='Para o Paciente' icon='jam jam-user-circle'
                        @click='imprimirOrcamento(dialogOpcoesImpressao.id)')

        Dialog.dialogCancelar(header='Cancelar orçamento' :modal='true' :visible.sync='dialogCancelar')
            .p-grid.p-fluid
                .p-col-12.ta-center Deseja cancelar o orçamento <b>{{ dialogCancelar_data.id }}</b>?
                .p-col-12
                    label.form-label Motivo *:
                    Textarea(v-model='dialogCancelar_data.ds_informacoes' rows='3' cols='30')
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingCancelar' strokeWidth='6')
                    Button.p-button-warning(v-else icon='jam jam-minus-circle' label='Confirmar'
                        @click='cancelarOrcamento()')

        Dialog.dialogAutorizar(header='Liberar guia' :modal='true' :visible.sync='dialogAutorizar')
            .p-grid.p-fluid
                .p-col-12.ta-center Deseja Autorizar o orçamento <b>{{ dialogAutorizar_data.id }}</b>?
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingCancelar' strokeWidth='6')
                    Button.p-button-warning(v-else icon='jam jam-minus-circle' label='Confirmar'
                        @click='autorizar(dialogAutorizar_data.id, true)')

        Dialog.dialogAutorizar(header='Cancelar liberção de guias' :modal='true' :visible.sync='dialogDesautorizar')
            .p-grid.p-fluid
                .p-col-12.ta-center Deseja Desautorizar o orçamento <b>{{ dialogDesautorizar_data.id }}</b>?
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingCancelar' strokeWidth='6')
                    Button.p-button-warning(v-else icon='jam jam-minus-circle' label='Confirmar'
                        @click='autorizar(dialogDesautorizar_data.id, false)')

        

        //- Dialog.dialogCancelarPagamento(header='Cancelar pagamento' :modal='true' :visible.sync='dialogCancelarPagamento')
        //-     .p-grid.p-fluid
        //-         .p-col-12.ta-center Deseja realmente desfazer o pagamento do orçamento <b>{{ dialogCancelarPagamento_data.id }}</b>?
        //-         .p-col-12
        //-             label.form-label Motivo *:
        //-             Textarea(v-model='dialogCancelarPagamento_data.ds_informacoes' rows='3' cols='30')
        //-         .p-col-12.ta-center
        //-             ProgressSpinner(v-if='waitingCancelarPagamento' strokeWidth='6')
        //-             Button.p-button-danger(v-else icon='jam jam-coin' label='Confirmar'
        //-                 @click='cancelarPagamento()' style="width: 150px")


        Dialog.dialog-movimentacoes(:visible.sync="dialogMovimentacoes.visible" :modal="true"
            :header="`Movimentações (Orçamento: ${ dialogMovimentacoes.cd_orcamento })`")
            .ta-center.my-4(v-if="dialogMovimentacoes.waiting")
                ProgressBar(mode="indeterminate")
            .ta-center(v-else-if="! dialogMovimentacoes.list.length")
                span Nenhuma movimentação encontrada.
            div(v-else)
                DataTable(:value="dialogMovimentacoes.list")
                    Column(headerStyle='width: 5%' bodyStyle='text-align:center;padding:0')
                        template(#body='props')
                            .status-indicator(:class="{ off: ! props.data.ie_status }" v-tooltip.top="`${props.data.ie_status ? '' : 'Não '}Vigente`")
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size:.8em' field='ds_informacoes' header='Informações')
                    Column(headerStyle='width: 10%;' header='Situação' bodyStyle='padding:0; font-size:.9em;')
                        template(#body='props')
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 1"
                                :class="{s1: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 2"
                                :class="{s2: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 3"
                                :class="{s3: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 4"
                                :class="{s4: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 5"
                                :class="{s5: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                    Column(headerStyle='width: 15%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center' field='cd_usuario_cri.username' header='Usuário')
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center' field='dt_criado_f' header='Dt Criado')
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center' field='dt_validacao_f' header='Dt Validado')
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center' field='dt_execucao_f' header='Dt Executado')
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center' header='Cod. guia')
                        template(#body='props')
                            router-link(
                                v-tooltip.top="'Ver transação'"
                                :to="{ path: `/transacoes-financeiras/visualizar/${ props.data.cd_transacao_financeira }/` }"
                            ) {{ props.data.cd_transacao_financeira }}

        Dialog.dialogVerHistorico(header='Ver histórico' :visible.sync='dialogVerHistorico' :modal='true')
            .ta-center.my-4(v-if='dialogVerHistorico_data.waiting')
                ProgressSpinner(strokeWidth='6')
            div(v-else)
                DataTable(:value="dialogVerHistorico_data.list")

                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' field='cd_paciente.nm_pessoa_fisica' header='Paciente')
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' field='ds_descricao' header='Cirurgia')
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size: .9em' header='Especialista(s)' field='nm_especialistas')
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size: .9em' field='cd_estabelecimento.nm_fantasia' header='Estabelecimento')
                    Column(headerStyle='width: 8%; font-size:.9em' bodyStyle='font-size: .9em; text-align:center' header='Conta aberta')
                        template(#body='props')
                            span {{ props.data.ie_conta_aberto ? 'Sim' : 'Não' }}
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size: .9em; text-align:right' header='Valor total')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_valor_total) }}
                    Column(headerStyle='width: 5%; font-size:.9em' bodyStyle='font-size: .9em; text-align:center')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/cirurgias/salvar/${ props.data.id }/?orcamento=true&edit=true`)"
                                )
                                
        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='false')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Nome do paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @input='checkEmptyField("nm_paciente")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Nome do especialista:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_especialista'
                            @input='checkEmptyField("nm_especialista")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Cirurgia:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_descricao'
                            @input='checkEmptyField("ds_descricao")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_procedimento'
                            @input='checkEmptyField("ds_procedimento")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Estabelecimento:
                    MultiSelect( 
                        :options='options.estabelecimentos'
                        placeholder='TODOS'
                        optionLabel='nm_fantasia'
                        optionValue='id'
                        dataKey='id' 
                        filter
                        v-model='filters.cd_estabelecimento_list'
                        @change='applyFilters()' 
                    )

                .p-col-12.p-md-2
                    label.form-label Situação:
                    ProgressBar(v-if='waitingSituacao' mode="indeterminate")
                    MultiSelect.input-situacao(
                        v-else
                        v-model='filters.cd_orcamento_situacao'
                        dataKey='value'
                        optionLabel='text'
                        optionValue='value'
                        :options='options.cd_orcamento_situacao'
                        :showToggleAll='false'
                        @hide='applyFilters()' )

                .p-col-12.p-md-2
                    label.form-label Tipo:
                    Dropdown( v-model='filters.ie_pacote' :options='options.ie_pacote'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODOS' @change='applyFilters()' )

                .p-col-6.p-md-3
                    label.form-label Data de criação:
                    .p-inputgroup
                        Calendar(
                            v-model='filters.dt_criado'
                            dateFormat="dd/mm/yy"
                            selectionMode="range"
                            :locale="ptbr"
                            :maxDate='new Date()'
                            :manualInput='false'
                            :touchUI='windowInnerWidth < 576'
                            @hide='applyFilters()'
                        )
                        Button.p-button-danger(v-tooltip.top="'Limpar'" icon='jam jam-rubber'
                            @click='filters.dt_criado=null; applyFilters()')

                .p-col-6.p-md-3
                    label.form-label Data de pagamento:
                    .p-inputgroup
                        Calendar(
                            v-model='filters.dt_pago'
                            dateFormat="dd/mm/yy"
                            selectionMode="range"
                            :locale="ptbr"
                            :maxDate='new Date()'
                            :manualInput='false'
                            :touchUI='windowInnerWidth < 576'
                            @hide='applyFilters()'
                        )
                        Button.p-button-danger(v-tooltip.top="'Limpar'" icon='jam jam-rubber'
                            @click='filters.dt_pago=null; applyFilters()')

                .p-col-6.p-md-3
                    label.form-label Data de execução:
                    .p-inputgroup
                        Calendar(
                            v-model='filters.dt_execucao'
                            dateFormat="dd/mm/yy"
                            selectionMode="range"
                            :locale="ptbr"
                            :maxDate='new Date()'
                            :manualInput='false'
                            :touchUI='windowInnerWidth < 576'
                            @hide='applyFilters()'
                        )
                        Button.p-button-danger(v-tooltip.top="'Limpar'" icon='jam jam-rubber'
                            @click='filters.dt_execucao=null; applyFilters()')

                .p-col-12.p-md-3
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSelecionarCirurgia = true")

        Panel.datatable-panel(header='Lista de orçamentos')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            div(style="overflow-x: scroll")
                DataTable.datatable(:value="list")
                    Column(headerStyle='width: 6em;' field='id' header='Cod.' bodyStyle='text-align:center')
                    Column(headerStyle='width: 15em;' field='nm_paciente' header='Paciente')
                    Column(headerStyle='width: 15em;' bodyStyle='overflow-wrap: break-word;' field='ds_descricao' header='Cirurgia')
                    Column(headerStyle='width: 12em;' header='Especialista(s)')
                        template(#body="props")
                            span {{ props.data.nm_especialistas }}
                            span.d-block.o-hidden(v-if="!props.data.isEspecialistaCadastrado" style="font-size:10px") (sem cadastro)
                    Column(headerStyle='width: 12em;' field='cd_estabelecimento.nm_fantasia' header='Estabelecimento')
                    Column(headerStyle='width: 7em;' header='Situação' bodyStyle='padding:0;')
                        template(#body='props')
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 1"
                                :class="{s1: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 2"
                                :class="{s2: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 3"
                                :class="{s3: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 4"
                                :class="{s4: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 5"
                                :class="{s5: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 6"
                                :class="{s4: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 7"
                                :class="{s5: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 8"
                                :class="{s5: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 9"
                                :class="{s2: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 10"
                                :class="{s3: true}") <b> {{ props.data.cd_orcamento_situacao.ds_situacao }} </b>
                    Column(headerStyle='width: 8em;' header='Valor total' bodyStyle='text-align:right')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_valor_total) }}
                    Column(headerStyle='width: 16em; text-align: center' header="Ações")
                        template(#body='props')
                            .ta-center
                                // Button.p-button-raised.p-button-rounded.mr-1(
                                    v-if='props.data.cd_paciente'
                                    v-tooltip.top="'Ver histórico'"
                                    icon="jam jam-clock"
                                    @click="openHistorico(props.data)"
                                    )
                                Button.p-button-raised.p-button-rounded.mr-1.mt-1(
                                    v-tooltip.top="'Imprimir Orçamento'"
                                    icon="jam jam-document"
                                    @click="dialogOpcoesImpressao.id=props.data.id; dialogOpcoesImpressao.visible=true"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-warning.mr-1.mt-1(
                                    v-if='([3, 10]).includes(props.data.cd_orcamento_situacao.id)'
                                    v-tooltip.top="'Imprimir Guias'"
                                    icon="jam jam-document"
                                    @click="imprimirTodasGuias(props.data.id)"
                                )
                                div(v-if="[1, 2, 9].includes(props.data.cd_orcamento_situacao.id) && \
                                    (usuario.nivel >= 5 || props.data.nm_usuario_cri === usuario.username)"
                                    style="display:contents")
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        icon="jam jam-write"
                                        @click="$router.push(`/cirurgias/salvar/${ props.data.id }/?orcamento=true&edit=true`)"
                                    )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-else
                                    v-tooltip.top="'Visualizar'"
                                    icon="pi pi-external-link"
                                    @click="$router.push(`/orcamentos-clinica/visualizar/${ props.data.id }`)"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Histórico de movimentações'"
                                    icon="jam jam-clock"
                                    @click="openMovimentacoesDialog(props.data.id)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                    v-if="[1, 2, 9].includes(props.data.cd_orcamento_situacao.id)"
                                    v-tooltip.top="'Cancelar orçamento'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogCancelar=true; dialogCancelar_data=props.data"
                                )
                                //- Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                //-     v-if="props.data.cd_orcamento_situacao.id === 3"
                                //-     v-tooltip.top="'Cancelar pagamento'"
                                //-     icon="jam jam-coin"
                                //-     @click="dialogCancelarPagamento=true; dialogCancelarPagamento_data=props.data"
                                //- )
                                Button.p-button-raised.p-button-rounded.p-button-success.mr-1(
                                    v-if="props.data.cd_orcamento_situacao.id === 2 && props.data.ie_recebivel"
                                    :disabled='!props.data.ie_status || !props.data.ie_recebivel || !props.data.cd_orcamento_cirurgia_procedimento_estabelecimento.find(item => item.cd_especialista != null)'
                                    v-tooltip.top="`${legendaAprovar(props.data)}`"
                                    icon="jam jam-coin"
                                    @click="verificaCaixa(props.data.id)"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    style = "background-color: #4f9c02; border: 1px solid #4f9c02;"
                                    v-if="(!props.data.ie_recebivel && props.data.cd_orcamento_situacao.id === 9)"
                                    v-tooltip.top="'Liberar guia'"
                                    icon="jam jam-check"
                                    @click="dialogAutorizar = true; dialogAutorizar_data = props.data"
                                )
                                Button.p-button-raised.p-button-rounded(
                                    style = "background-color: #c40606; border: 1px solid #c40606; "
                                    v-else-if='props.data.cd_orcamento_situacao.id === 10'
                                    v-tooltip.top="'Cancelar liberação de guia'"
                                    icon="jam jam-close"
                                    @click="dialogDesautorizar = true; dialogDesautorizar_data = props.data"
                                )

                    //- Column(headerStyle='width: 7em;' header='Dt. Criação' bodyStyle='text-align:center')
                    //-     template(#body='props')
                    //-         span(v-tooltip.top="`Usuário: ${props.data.nm_usuario_cri}`") {{ props.data.dt_criado_f }}
                    //- Column(headerStyle='width: 7em;' header='Dt. Pagamento' bodyStyle='text-align:center')
                    //-     template(#body='props')
                    //-         span(v-tooltip.top="`Usuário: ${props.data.nm_usuario_validacao}`") {{ props.data.dt_validacao_f }}
                    //- Column(headerStyle='width: 7em;' header='Dt. Execução' bodyStyle='text-align:center')
                    //-     template(#body='props')
                    //-         span(v-tooltip.top="`Usuário: ${props.data.nm_usuario_execucao || 'Não encontrado!'}`") {{ props.data.dt_execucao_f }}
                    Column(headerStyle='width: 5em;' header='Tipo' bodyStyle='text-align:center')
                        template(#body='props')
                            span {{ props.data.ie_conta_aberto ? 'Conta aberta' : 'Pacote' }}
                    template(#empty)
                        ProgressBar.mt-2.mb-2(v-if='waiting' mode="indeterminate")
                        .ta-center(v-else) Nenhum registro encontrado.

            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
        BlockUI(:blocked="waitingImprimir" :fullScreen="true")

</template>

<style lang="scss">
.orcamentos-listar {
    .datatable {
        min-width: 1100px;
    }
    .cell {
        padding: 16px 0;
        text-align: center;
        height:70px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.s1 {
            background-color: #eae9e9;//cinza
        }
        &.s2 {
            background-color: #fff1cc;//amarelado
        }
        &.s3 {
            background-color: #daf2c2;//verde
        }
        &.s4 {
            background-color: #00bdff;//azul
        }
        &.s5 {
            background-color: #fd6161;//vermelho
        }
        &.s6 {
            background-color: #e4f5d3;//verde-claro
        }
        &.s7 {
            background-color: #f79c9c;//vermelho-claro
        }
    }
    .dialogApagar,
    .dialogCancelar,
    .dialogCancelarPagamento,
    .dialogVerHistorico {
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .dialogVerHistorico {
        max-width: 98%;
        min-width: 360px;
        max-height: 480px;
    }
    .dialogCancelar,
    .dialogCancelarPagamento {
        width: 96%;
        max-width: 480px;
    }
    .border {
        border: 1px solid #ccc;
        border-radius: 10px;
        margin: 5px;
        padding: 10px;
        min-height: 150px;
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 24px;
        height: 24px;
        line-height: 36px;
        border-radius: 50%;
        transition: 250ms;
        cursor: pointer;
        background-color: #94c860;
        border: 1px solid #84b553;
        &.alt {
            background-color: #007ad9;
            border: 1px solid #0e6bb3;
        }

        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &.alt {
                background-color: #bbb;
                border: 1px solid #aaaaaa;
            }
        }
        .jam {
            color: #fff;
        }
    }
    .input-situacao .p-multiselect-header {
        display: none;
    }
    .dialog-movimentacoes {
        width: 900px;
        max-width: 98%;
    }
    .dialog-opcoes-impressao {
        width: 500px;
        max-width: 96%;
        overflow: auto;
    }
    .p-button-warning {
        max-width: 50%; 
    }
}
.p-tooltip {
    max-width: none;
}
</style>

<script>
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import Paginator from "primevue/paginator";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Tooltip from "primevue/tooltip";
import Imprimir from './../TransacoesFinanceiras/Imprimir';


import { Estabelecimento, Orcamento, Caixas } from "../../middleware";
import wsConfigs from "./../../middleware/configs";
import ListaCirurgias from "./ListaCirurgias";
import moment from "moment";
import { pCalendarLocale_ptbr } from "@/utils";

export default {
    components: {
        BlockUI,
        Button,
        Calendar,
        Column,
        DataTable,
        Dialog,
        Dropdown,
        InputText,
        ListaCirurgias,
        MultiSelect,
        Panel,
        Paginator,
        ProgressBar,
        ProgressSpinner,
        Textarea,
        Tooltip,
        Imprimir
    },
    beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'cirurgias' || from.path.split('/')[1] === 'orcamentos-clinica')  && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
                    vm.applyFilters()
                    vm.$store.commit('clearFilters')
			})
		},
	beforeRouteLeave (to, from, next) {
			// let routeName = this.$route.path.split('/')[1] 
			if (["cirurgias","orcamentos-clinica"].includes(to.path.split('/')[1]))
				if (this.filters) {
                    this.$store.commit('setFilters', this.filters)
                }
			next()
		},
    created() {
        this.waiting = true;
        this.waitingSituacao = true;
        Orcamento.orcamentoSituacao().then(response => {
            if(([200, 201]).includes(response.status)){
                this.options.cd_orcamento_situacao = response.data.map(item => ({
                    text: item.ds_situacao,
                    value: item.id
                }))
            }
            this.waitingSituacao= false;
        })
        Estabelecimento.findAllClean().then(response => {
            if (response.status === 200)
                this.options.estabelecimentos = this.options.estabelecimentos.concat(
                    response.data
                );
            // this.applyFilters();

            this.usuario = JSON.parse(localStorage.getItem("usuario"));
        });
    },
    directives: { tooltip: Tooltip },
    data() {
        return {
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            usuario: null,
            waiting: true,
            waitingApagar: false,
            waitingSituacao: false,
            waitingImprimir: false,
            waitingCancelar: false,
            waitingAutorizar: false,
            waitingDesautorizar: false,
            waitingCancelarPagamento: false,
            list: [],
            filters: {
                nm_paciente: null,
                nm_especialista: null,
                ds_procedimento: null,
                cd_estabelecimento: null,
                ds_descricao: null,
                cd_orcamento_situacao: [1, 2, 3, 4, 6, 7, 8, 9, 10],
                ie_pacote: null,
                dt_criado: null,
                dt_pago: null,
                dt_execucao: null
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            options: {
                estabelecimentos: [],
                cd_orcamento_situacao: [],
                ie_pacote: [
                    { value: null, text: "TODOS" },
                    { value: true, text: "Pacote" },
                    { value: false, text: "Conta Aberta" }
                ]
            },
            dialogApagar: false,
            dialogApagar_data: {},
            dialogSelecionarCirurgia: false,
            dialogVerHistorico: false,
            dialogVerHistorico_data: {},
            dialogCancelar: false,
            dialogCancelar_data: {},
            dialogAutorizar_data: {},
            dialogDesautorizar_data: {},
            dialogAutorizar: false,
            dialogDesautorizar: false,
            dialogCancelarPagamento: false,
            dialogCancelarPagamento_data: {},
            dialogMovimentacoes: {
                visible: false,
                waiting: false,
                cd_orcamento: null,
                list: []
            },
            dialogOpcoesImpressao: {
                visible: false,
                id: null,
                waiting: false
            },
            dialogOpcoesImpressaoGuias: {
                visible: false,
                id: null,
                waiting: false,
                cd_transacao: null
            },
            guias: 0,
            legendaAprovar(item) {
            let legenda = 'Confirmar Pagamento';
            if(!item.ie_status)
                legenda = 'Desabilitado'
            else if(!item.ie_recebivel)
                legenda = 'Não recebível'
            else if(!item.cd_orcamento_cirurgia_procedimento_estabelecimento.find(item => item.cd_especialista != null))
                legenda = 'Especialista não adicionado'
            return legenda;
            }
        };
    },
    methods: {
        getList(params) {
            this.waiting = true;
            this.list = [];
            return Orcamento.findAll(params).then(response => {
                if (response.status == 200) {
                    this.paginator.count = response.data.count;
                    this.list = response.data.results;
                    this.list.forEach(item => {
                        if (item.dt_criado)
                            item.dt_criado_f = moment(item.dt_criado).format(
                                "DD/MM/YYYY"
                            );
                        if (item.dt_validacao)
                            item.dt_validacao_f = moment(
                                item.dt_validacao
                            ).format("DD/MM/YYYY");
                        if (item.dt_execucao)
                            item.dt_execucao_f = moment(
                                item.dt_execucao
                            ).format("DD/MM/YYYY");

                        let ocpe =
                            item
                                .cd_orcamento_cirurgia_procedimento_estabelecimento?.[0];
                        item.nm_especialistas = ocpe?.nm_especialista || "";
                        item.isEspecialistaCadastrado = !!ocpe?.cd_especialista;

                        item.nm_paciente = item.cd_paciente
                            ? item.cd_paciente.nm_pessoa_fisica
                            : item.nm_paciente;
                    });
                }
                this.waiting = false;
                return true;
            });
        },
        applyFilters(page) {
            this.paginator.page = page || 1;

            let params = {
                paginacao: true,
                page: this.paginator.page,
                per_page: this.paginator.per_page
            };
            if (this.$route.query.pg != this.paginator.page)
                this.$router.replace({ query: { pg: this.paginator.page } });
            Object.keys(this.filters).forEach(key => {
                if (this.filters[key] || this.filters[key] === false) {
                    if (key.substr(0, 3) === "dt_") {
                        params[key + "_inicial"] = moment(
                            this.filters[key][0]
                        ).format("YYYY-MM-DD");
                        if (this.filters[key][1]) {
                            params[key + "_final"] = moment(
                                this.filters[key][1]
                            ).format("YYYY-MM-DD");
                        }
                    } else params[key] = this.filters[key];
                }
            });
            this.getList(params);
        },
        onPage(ev) {
            this.applyFilters(ev.page + 1);
        },
        checkEmptyField(field) {
            if (!this.filters[field].length) this.applyFilters();
        },
        clearFilters() {
            Object.keys(this.filters).forEach(k => (this.filters[k] = null));
            this.applyFilters();
        },
        openHistorico(data) {
            this.dialogVerHistorico_data = { waiting: true };
            this.dialogVerHistorico = true;
            Orcamento.findAll({ nr_cpf: data.cd_paciente.nr_cpf }).then(
                response => {
                    this.dialogVerHistorico_data.waiting = false;
                    if (response.status === 200)
                        this.dialogVerHistorico_data.list = response.data;
                }
            );
        },
        imprimirOrcamento(id, medico = false) {
            this.waitingImprimir = true;
            Orcamento.imprimir(id, { pdf: true, medico }).then(response => {
                this.waitingImprimir = false;
                if ([200, 201, 204].includes(response.status)) {
                    let blob = new Blob([response.data], {
                        type: "application/pdf; charset=utf-8"
                    });
                    const fileURL = URL.createObjectURL(blob);
                    let a = window.open(fileURL);
                    a.print();
                    this.dialogOpcoesImpressao.visible = false;
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
            });
        },
        imprimirGuias (termica=false) {
            this.$refs.boxImprimir.imprimirGuias(termica)
            this.dialogOpcoesImpressaoGuias.visible = false
        },
        imprimirTodasGuias (id) {
            Orcamento.find(id).then(response => {
                if(([200, 201]).includes(response.status)){
                    this.dialogOpcoesImpressaoGuias.cd_transacao = response.data.cd_transacao_financeira
                    this.dialogOpcoesImpressaoGuias.visible =  true
                }
            })
        },
        setGuias(val) {
            this.guias = val
        },
        imprimirTermica() {
            this.waitingImprimirTermica = true
            Caixas.findTransacaoPDF(this.dialogOpcoesImpressaoGuias.cd_transacao).then(response => {
                this.waitingImprimirTermica = false
                if (response.status == 200) {
                    if (response.data.detail) {
                        this.$toast.error(response.data.detail, { duration: 3000 })
                    } else {
                        let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
                        var fd = new FormData();
                        fd.append('file', blob);
                        fetch('http://localhost:9990/imprimir', {
                            method: 'POST',
                            body: fd
                        })

                        //let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                        //var fileURL = URL.createObjectURL(blob);
                        //let a = window.open(fileURL);
                        //a.print()
                        //saveAs(blob, `comprovante-transacao-${ this.transacaoId }.pdf`)
                    }
                }
            })
        },
        cancelarOrcamento() {
            if (!this.dialogCancelar_data.ds_informacoes) {
                this.$toast.error("Digite o motivo do cancelamento.");
                return;
            }

            let dataSend = {
                ds_informacoes: this.dialogCancelar_data.ds_informacoes
            };

            this.waitingCancelar = true;
            Orcamento.cancelar(this.dialogCancelar_data.id, dataSend).then(
                response => {
                    this.waitingCancelar = false;
                    if ([200, 201].includes(response.status)) {
                        this.dialogCancelar_data.cd_orcamento_situacao =
                            response.data.cd_orcamento_situacao;
                        this.dialogCancelar = false;
                        this.dialogCancelar_data = {};
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == "string")
                            this.$toast.error(response.data.detail, {
                                duration: 3000
                            });
                        else
                            response.data.detail.forEach(msg =>
                                this.$toast.error(msg, { duration: 3000 })
                            );
                    }
                }
            );
        },
        cancelarPagamento() {
            if (!this.dialogCancelarPagamento_data.ds_informacoes) {
                this.$toast.error("Digite o motivo do cancelamento.");
                return;
            }

            let dataSend = {
                ds_informacoes: this.dialogCancelarPagamento_data.ds_informacoes
            };

            this.waitingCancelarPagamento = true;
            Orcamento.cancelarPagamento(
                this.dialogCancelarPagamento_data.id,
                dataSend
            ).then(response => {
                this.waitingCancelarPagamento = false;
                if ([200, 201, 204].includes(response.status)) {
                    this.$toast.success("Pagamento cancelado com sucesso.");
                    this.dialogCancelarPagamento_data.cd_orcamento_situacao =
                        response.data.cd_orcamento_situacao;
                    this.dialogCancelarPagamento_data = {};
                    this.dialogCancelarPagamento = false;
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
            });
        },
        openMovimentacoesDialog(cd_orcamento) {
            this.dialogMovimentacoes.cd_orcamento = cd_orcamento;
            this.dialogMovimentacoes.visible = true;
            this.dialogMovimentacoes.waiting = true;
            Orcamento.findAllMovimentacao(cd_orcamento).then(response => {
                this.dialogMovimentacoes.waiting = false;
                if ([200].includes(response.status)) {
                    this.dialogMovimentacoes.list = response.data;
                    this.dialogMovimentacoes.list.forEach(item => {
                        item.dt_criado_f = moment(item.dt_criado).format(
                            "DD/MM/YYYY HH:mm"
                        );
                        item.dt_atualizado_f = item.dt_atualizado? moment(item.dt_criado).format(
                            "DD/MM/YYYY HH:mm"
                        ): '';
                        const auxitem = this.list.find(i => i.id == item.cd_orcamento_cirurgia)
                        item.dt_validacao_f = auxitem?.dt_validacao_f? auxitem.dt_validacao_f : '';
                        item.dt_execucao_f = auxitem?.dt_execucao_f? auxitem.dt_execucao_f : '';
                    });
                }
            });
        },
        verificaCaixa(id) {
            Caixas.checkCaixaAtivo().then(response => {
                if (!([200, 201]).includes(response.status) || !response?.data?.ie_ativo) {
				this.$router.push({path:"/caixa/listar-guias", query:{pg:1, id: id, aprovar: true, retorno: true}})
                } else {
                    this.$router.push(`/orcamentos-clinica/visualizar/${id}`);
                }
            });
        },
        autorizar(id, autorizar){
            Orcamento.autorizar({id: id, ie_autorizar: autorizar}).then(response => {
                if(([200, 201, 204]).includes(response.status)){
                      this.$toast.success(`Procedimento ${ autorizar? 'Autorizado!' : 'Desautorizado' }`, {
                            duration: 3000
                        });
                }else{
                    this.$toast.error(response.data.detail, {
                                                duration: 3000
                                            });
                }
                this.dialogAutorizar = false;
                this.dialogAutorizar_data = {};
                this.dialogDesautorizar = false;
                this.dialogDesautorizar_data = {};
                this.applyFilters()
            })
        },
        // desautorizar(id){
        //     Orcamento.desautorizar(id).then(response => {
        //         if(([200, 201, 204]).includes(response.status)){
        //               this.$toast.success("Procedimento Desautorizado!", {
        //                     duration: 3000
        //                 });
        //         }else{
        //             this.$toast.error(response.data.detail, {
        //                                         duration: 3000
        //                                     });
        //         }
        //     })
        //     this.dialogDesautorizar = false;
        //     this.dialogDesautorizar_data = {};
        //     this.applyFilters()
        // }
    }
};
</script>
