<template lang="pug">
    Dialog.dialogSelecionarCirurgia(header='Selecionar cirurgia' :visible.sync='$parent.dialogSelecionarCirurgia' :modal='true')
        .p-grid.p-align-end

            .p-col-8.p-fluid
                label.form-label Descrição:
                .p-inputgroup
                    InputText(
                        placeholder='Descrição'
                        @keyup.enter.native='applyFilters()'
                        @input='value => value || applyFilters()'
                        v-model='filters.ds_descricao'
                        )
                    Button(icon='jam jam-search' @click='applyFilters()')

            .p-col-4.ta-right
                Button.mb-2(label='Sem cirurgia' style='font-size:.88em' icon="jam jam-chevrons-right" iconPos="right" @click="$router.push('/cirurgias/salvar/0/?orcamento=true')")

        .ta-center.waiting(v-if='waiting')
            ProgressBar(mode="indeterminate")
        div(v-else)
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value='list')
                Column(headerStyle='width: 35%; font-size:.88em' bodyStyle='font-size:.88em; overflow-wrap: break-word;' field='ds_descricao' header='Descrição')
                Column(headerStyle='width: 35%; font-size:.88em' bodyStyle='font-size:.88em' field='cd_estabelecimento.nm_fantasia' header='Estabelecimento')
                Column(headerStyle='width: 20%; font-size:.88em' bodyStyle='font-size:.88em; text-align:right' header='Valor total')
                    template(#body='props')
                        span(v-tooltip.top="props.data.nr_valor_total") {{ $root.formatPrice(props.data.nr_valor_total) }}
                Column(headerStyle='width: 10%; font-size:.88em')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded(
                                v-tooltip.top="'Criar orçamento'"
                                icon="jam jam-chevron-right"
                                @click="$router.push(`/cirurgias/salvar/${ props.data.id }/?orcamento=true`)"
                        )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .dialogSelecionarCirurgia {
        .p-dialog-content {
            overflow-y: auto !important;
            max-height: 32em;
        }
        .p-datatable {
            width: 100%;
            max-width: 640px;
        }
        .waiting {
            width: 640px;
            text-align: center;
            padding: 4em 0;
            .p-progress-spinner {
                width: 4em;
                height: auto;
            }
        }
    }
</style>

<script>
    import Button from 'primevue/button'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Paginator from 'primevue/paginator'
    import ProgressBar from 'primevue/progressbar'
    import Tooltip from 'primevue/tooltip'

    import { Cirurgia } from '../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        components: { Button, Column, DataTable, Dialog, InputText, Paginator, ProgressBar, Tooltip },
        directives: { tooltip: Tooltip },
        created () {
            this.applyFilters()
        },
        data () {
            return {
                list: [],
                waiting: false,
                filters: {
                    ie_status: true
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return Cirurgia.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            }
        }
    }
</script>
